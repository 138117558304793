import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import AOS from 'aos'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import {bookADemoLinkSwitch} from '../../components/lib/bookADemoLinkSwitch'
import FeaturedPlatformPagesSection from '../../components/FeaturedPlatformPagesSection'
import CustomerStorySliderSection from '../../components/CustomerStorySliderSection'
import BookDemoSection from '../../components/BookDemoSection'
import TrustedBySection from '../../components/TrustedBySection'

import stars from '../../images/home2022/MyHR_Home_Stars.png'
import capterra from '../../images/home2022/MyHR_Home_Capterra.png'
import google from '../../images/home2022/MyHR_Home_Google.png'

import customerStories from '../../data/customerStories'

import '../au/book-a-demo-new.scss'
import 'aos/dist/aos.css'

class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '1be7da00-c34a-4cea-bdf7-2d4c494421e4',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div id="hubspotForm"></div>
    );
  }
}

export default () => {
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  var locale = {}
  locale.value = "en-NZ"
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <Layout className="BookDemo-new" title="Book a demo with MyHR | Make HR Easy" description="We'd love to learn more about your HR challenges and priorities, show you the platform and explore if MyHR is the right fit for your team and business. Book a demo free, no-obligation demo today." locale="en-NZ">
      <Section className="pb-6 bg-gray" id="scrollToForm">
        <Container>
          <Row className="py-4">
            <Col md={7} className="px-4 header-banner">
              <h4>Book your personalised demo</h4>
              <h1>See how MyHR can<br />help you make HR easy</h1>
              <ul className="large">
                <li>Agreements, policies, and HR compliance</li>
                <li>Automated performance, payroll and onboarding</li>
                <li>Expert HR support for any employment issue</li>
              </ul>
              <div className="d-flex align-items-center flex-row justify-content-start">
                <div className="capterra d-flex align-items-center flex-row">
                  <img src={capterra} className="logo" />
                  <img src={stars} className="stars" />
                </div>
                <div className="google d-flex align-items-center flex-row">
                  <img src={google} className="logo" />
                  <img src={stars} className="stars" />
                </div>
              </div>
            </Col>
            <Col md={5} className="px-4">
              <h3 className="form-header">Fill in your details and an HR expert will call you back.</h3>
              <HubspotForm />
            </Col>
          </Row>
        </Container>
      </Section>

      <TrustedBySection
        items={[
          <img src="//images.ctfassets.net/euxb051oplm7/7tVsftUJJOfWtvtgtABIdc/9929e636dfd04c5f386ee7ab5b5e9e4e/Hell_Pizza_Logo.png" alt="Hell" />,
          <img src="//images.ctfassets.net/euxb051oplm7/4PErs9z5Tq6I14YscMoHO9/1628f87067ae0c2fbcdbdf92569d3eeb/Warriors_Logo.png" alt="The Warriors" />,
          <img src="//images.ctfassets.net/euxb051oplm7/2MJJIuea1ga2T40TUbx5BH/ad328f6a721665bd650590636d973917/Barkers_Logo.png" alt="Barkers" />,
          <img src="//images.ctfassets.net/euxb051oplm7/6wUzGpHwKkcncC5ucEGDpu/a910f1087c21edbe7a63fe63c9388b98/Mazda_Logo.png" alt="Mazda" />,
          <img src="//images.ctfassets.net/euxb051oplm7/5w72c4FZqWC1Wf4nHOx7II/59badd3061cc0146b0808cf3d01fa426/WWF_Logo.png" alt="WWF" />,
          <img src="//images.ctfassets.net/euxb051oplm7/L6PVzHKXPC1ctyKUtDkRq/4811e1e74d9dd30374c5a791ebc192ba/Max_Fashion_Logo.png" alt="Max Fashion" />
        ]}
      />

      <FeaturedPlatformPagesSection />

      <CustomerStorySliderSection
        title="Hear why SMEs across New Zealand choose MyHR"
        stories={[
          customerStories['save-the-kiwi'],
          customerStories['parkable'],
          customerStories['kaar']
        ]}
      />
      
      <BookDemoSection
        title="Start using MyHR for free"
        text={(
          <p>Not quite ready to talk to us yet? See if MyHR is the right solution for your business with a free trial.</p>
        )}
        showBookButton={false}
        trialButtonStyle="navy"
        extraButtons={(
          <span>Or, <a href="/nz/pricing">compare pricing</a></span>
        )}
      />
    </Layout>
  )
}
